<template>
  <div class="landing-page">
    <div class="mobile-menu">
      <ul id="site-menu" class="navbar-nav">
      </ul>
    </div>

    <div class="main-container">
      <topNav></topNav>

      <div class="content-container" id="home">
        <div class="section home">
          <div class="container pt-5">
            <div class="row pt-5 mt-4 mb-4">
              <div class="container col-12 text-center">
                <!--<h1 class="text-white-50">Stats</h1>-->
                <h2 class="text-white-50">
                  You asked, We delivered!
                </h2>
              </div>

              <div class="container col-12 col-xl-3 col-lg-3 col-md-3 text-center">
                <div class="price-top-part">
                  <i class="iconsmind-MaleFemale large-icon"></i>
                  <h5 class="mb-0 font-weight-semibold text-white-50 mb-4">Players</h5>
                  <p class="text-large mb-2 text-white"><span class="notranslate" id="getTotalMembers">...</span></p>
                  <p class="text-small text-white-50">Unique Impressions</p>
                </div>
              </div>
              <div class="container col-12 col-xl-3 col-lg-3 col-md-3 text-center">
                <div class="price-top-part">
                  <i class="iconsmind-Coins large-icon"></i>
                  <h5 class="mb-0 font-weight-semibold text-white-50 mb-4">TVL</h5>
                  <p class="text-large mb-2 text-white"><span class="notranslate" id="contractBalance">...</span></p>
                  <p class="text-small text-white-50">USD</p>
                </div>
              </div>

              <div class="container col-12 col-xl-3 col-lg-3 col-md-3 text-center">
                <div class="price-top-part">
                  <i class="iconsmind-Sync large-icon"></i>
                  <!--<h5 class="mb-0 font-weight-semibold color-theme-1 mb-4">Transactions</h5>-->
                  <h5 class="mb-0 font-weight-semibold text-white-50 mb-4">Transactions</h5>
                  <p class="text-large mb-2 text-white"><span class="notranslate" id="totalTxs">...</span></p>
                  <p class="text-small text-white-50">Txs</p>
                </div>
              </div>

            </div>


            <div class="row mb-4">

              <div class="container col-12 col-xl-6 col-lg-6 col-md-3 mb-4 ">
                <div class="home-text text-center row mb-2 mt-4">
                  <div class="col-12 text-center">
                    <div class="mb-4 text-center notranslate p-1"><img width="120px" src="./assets/img/logo/rake_notext.png" /></div>
                    <p class="mb-4 text-white"><span class="notranslate" id="rake-apy">...</span></p>
                    <p class="luck-title-top  mb-4 text-center"><a href="/rake.html" class="btn btn-outline-primary text-white mb-4">LAUNCH APP</a></p>
<!--                    <h3 class="text-white-50">Get those gains...</h3>-->
                  </div>
                </div>
              </div>

              <div class="container col-12 col-xl-6 col-lg-6 col-md-3 mb-4 ">
                <div class="home-text text-center row mb-2 mt-4">
                  <div class="col-12 text-center">
                    <div class="mb-4 text-center notranslate p-1"><img width="120px" src="./assets/img/logo/br34p.png" /></div>
                    <p class="mb-4 text-white"><span class="notranslate" id="br34p-apy">...</span></p>
                    <p class="luck-title-top  mb-4 text-center"><a href="/br34p.html" class="btn btn-outline-primary text-white mb-4">LAUNCH APP</a></p>
<!--                    <h3 class="text-white-50">Low risk growth...</h3>-->
                  </div>
                </div>
              </div>

              <div class="container col-12 col-xl-6 col-lg-6 col-md-6 mb-4 pt-4">
                <div class="text-center">
                  <h1 class="text-white-50">About</h1>

                </div>

                <p class="text-white text-justify">SILO is a noninflationary single asset staking platform for RAKE and bR34P designed to remove RAKE and bR34P from circulation and pay rewards in RAKE and bR34P through a value transfer from weak hands to strong hands using a 10% tax on deposits and withdrawals.  2% of  the tokens collected from this tax are paid out instantly to token stakers in SILO and the rest go into a pool which is then drip distributed at a rate of 2% of the pool daily.</p>
              </div>
            </div>
          </div>

          <foot/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topNav from "@/components/topNav";
import foot from "@/components/foot";

export default {
  name: 'App',
  components: {
    topNav,
    foot
  },
  mounted() {
    require('./assets/js/home.bundle');
    require('./assets/js/index');
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
