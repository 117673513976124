import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


import './assets/fonts/iconsmind/style.css';
import './assets/fonts/simple-line-icons/css/simple-line-icons.css';
import './assets/css/main.css';
// import './assets/js/home.bundle';
// import './assets/js/index';
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
